<template>
    <BT-Blade-Items
        bladeName="public-suppliers"
        navigation="public-suppliers"
        title="Browse Suppliers"
        :useServerPagination="true"
        frameVariant="opaque">
        <template v-slot:body="{ items }">
            <v-container fluid>
                <v-row>
                    <v-col
                        v-for="(item, index) in items"
                        :key="index"
                        cols="12" sm="6" md="4" lg="3">
                        <v-card class="ma-3">
                            <v-img 
                                height="100"
                                width="100"
                                class="mx-auto"
                                :src="companyLogoURL(item.id)">
                                <template v-slot:placeholder>
                                    <v-icon size="100" color="primary">mdi-factory</v-icon>
                                </template>
                            </v-img>

                            <v-card-title>{{ item.companyName }}</v-card-title>

                            <v-divider class="mx-4" />

                            <v-card-text>
                                {{ item.publicDescription ? item.publicDescription : 'Another supplier using the BlitzIt Web Platform for their small business.' }}
                            </v-card-text>

                            <v-card-actions>
                                <v-btn
                                    text
                                    :to="{ name: 'public-supplier', params: { id: item.id }}">Explore<v-icon right>mdi-arrow-right</v-icon></v-btn>
                            </v-card-actions>
                        </v-card>
                    </v-col>
                </v-row>
            </v-container>
        </template>
    </BT-Blade-Items>
</template>

<script>
export default {
    name: 'Public-Suppliers-Blade'
}
</script>

